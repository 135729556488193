import useTranslation from 'next-translate/useTranslation';
import StandaloneContentModal from '@ui/components/Content/StandaloneContentModal';
import useGetIcon from '@utils/hooks/useGetIcon';
import { useConstants } from '@web/context/ConstantContext';
import useStandaloneContent from '@web/context/hooks/useStandaloneContent';
import { useSettings } from '@web/context/SettingsContext';

const CONTENT_IDENTIFYER = 'protect-group';

const ProtectGroupInformationModal = ({
  isOpen,
  toggleModal,
}: {
  isOpen: boolean;
  toggleModal: () => void;
}) => {
  const { t } = useTranslation();
  const title = t('refundable_booking');
  const { locale } = useConstants();
  const { residency } = useSettings();

  const closeIcon = useGetIcon()('closeIcon');

  const { content } = useStandaloneContent('protect-group');

  return (
    <StandaloneContentModal
      closeIcon={closeIcon}
      content={content}
      id={CONTENT_IDENTIFYER}
      isOpen={isOpen}
      locale={locale}
      residency={residency}
      title={title}
      toggleModal={toggleModal}
    />
  );
};

export default ProtectGroupInformationModal;
